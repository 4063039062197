<template>
    <div class="layout-hero mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
        <div class="max-w-2xl mb-6 lg:mb-0 lg:col-span-2 xl:col-auto">
            <slot name="title"></slot>
        </div>
        <div class="max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
            <slot name="text"></slot>
            <div class="mt-10 flex items-center gap-x-6" v-if="$slots.cta">
                <slot name="cta"></slot>
            </div>
        </div>
        <div class="mt-10 w-full max-w-lg sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36" :class="imageAspectRatio">
            <slot name="image"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LayoutHero',
    props: {
        imageAspectRatio: {
            required: false,
            type: String,
            default: 'aspect-[6/5]',
        },
    },
};
</script>
