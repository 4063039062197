<template>
    <sections>
        <section-title
            :imageSizes="hero.imageSizes"
            :imageSet="hero.imageSet"
            :image="hero.image"
            imageAspectRatio="aspect-[23/8]"
            heading="IF YOUR LOCKS LOOK LIKE THIS"
            content="<article-home-headline />"
        ></section-title>
        <section-text-columns
            v-bind="{
                contentColumn1: '<article-home-text-left />',
                contentColumn2: '<article-home-text-right />',
            }"
        ></section-text-columns>
        <section-hero
            v-bind="{
                heading: 'CALL ' + $config.public.companyTelephone,
                content: '<p>We will supply and fit you a cylinder lock that is truly snap-secure, anti-bump and anti-pick, at the best possible rates.</p>',
            }"
        ></section-hero>
    </sections>
</template>

<script setup>
const $config = useRuntimeConfig();

// https://www.responsivebreakpoints.com/
// https://cloudinary.com/tools/jpg-to-webp
const hero = {
    imageSizes: '100vw',
    imageSet: `
        ${$config.public.images}insecure-locks-920.webp 920w
    `,
    image: `${$config.public.images}insecure-locks.jpg`,
};

useSeo().buildHead({
    title: $config.public.siteName,
    description: $config.public.siteStrapline,
    index: true,
});
</script>
