<template>
    <div class="section-hero" :class="color">
        <layout-contained :class="padding">
            <layout-hero>
                <template v-slot:title>
                    <content-headline
                        v-if="heading"
                        :heading="heading"
                        :headingColor="headingColor"
                        :headingComponent="headingComponent"
                    ></content-headline>
                </template>
                <template v-slot:text>
                    <content-text
                        v-if="content"
                        :html="content"
                        :contentColor="contentColor"
                    ></content-text>
                    <div v-if="buttonText" class="mt-8">
                        <button-primary
                            :buttonColor="buttonColor || undefined"
                            :buttonRoute="buttonRoute"
                            :buttonText="buttonText"
                            :buttonClick="buttonClick"
                            :buttonClickParams="buttonClickParams"
                        ></button-primary>
                    </div>
                    <slot name="custom"></slot>
                </template>
                <template v-slot:image>
                    <image-hero
                        v-if="image"
                        :image="image"
                        :imageSet="imageSet"
                        :imageSizes="imageSizes"
                        :imageAlt="imageAlt"
                        :imageAspectRatio="imageAspectRatio"
                        :imageObjectFit="imageObjectFit"
                        :imageObjectPosition="imageObjectPosition"
                        :imageLoading="imageLoading"
                        :imagePriority="imagePriority"
                    />
                </template>
            </layout-hero>
        </layout-contained>
    </div>
</template>

<script>
export default {
    name: 'SectionHero',
    props: {
        padding: {
            required: false,
            default: 'pt-12 pb-16 sm:pt-14',
            type: String,
        },
        buttonColor: {
            required: false,
            type: String,
            default: null,
        },
        buttonRoute: {
            required: false,
            type: [String, Object],
            default: null,
        },
        buttonText: {
            required: false,
            type: String,
            default: null,
        },
        buttonClick: {
            required: false,
            type: Function,
            default: () => {},
        },
        buttonClickParams: {
            required: false,
            type: [String, Object, Array],
            default: null,
        },
        color: {
            required: false,
            type: String,
            default: 'bg-zinc-900',
        },
        content: {
            required: false,
            type: String,
            default: null,
        },
        contentColor: {
            required: false,
            type: String,
            default: 'text-zinc-400',
        },
        heading: {
            required: false,
            type: String,
            default: null,
        },
        headingColor: {
            required: false,
            type: String,
            default: 'text-yellow-400',
        },
        headingComponent: {
            required: false,
            type: String,
            default: 'h1',
        },
        image: {
            required: false,
            type: String,
            default: null,
        },
        imageSet: {
            required: false,
            type: String,
            default: null,
        },
        imageSizes: {
            required: false,
            type: String,
            default: null,
        },
        imageAlt: {
            required: false,
            type: String,
            default: '',
        },
        imageAspectRatio: {
            required: false,
            type: String,
            default: 'aspect-[6/5]',
        },
        imageObjectFit: {
            required: false,
            type: String,
            default: undefined,
        },
        imageObjectPosition: {
            required: false,
            type: String,
            default: undefined,
        },
        imageLoading: {
            required: false,
            type: String,
            default: undefined,
        },
        imagePriority: {
            required: false,
            type: String,
            default: undefined,
        },
    },
};
</script>
